<template>
  <v-container class="contact-container">
    <v-main id="contact">
      <v-row class="mt-5 mb-5">
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title>
                  {{$t('home.about.events.cup')}}
                </v-card-title>
                <v-card-subtitle>
                  <v-row>
                    <v-col cols="4" sm="3" md="4" class="pb-0 pt-0 contact-logos-container">
                      <v-img src="https://ebikecup.com/images/EBCUP_LOGO.svg"/>
                    </v-col>
                    <v-col cols="8" sm="9" md="8" class="pb-0 pt-0">
                    </v-col>
                    <v-col cols="12" class="pt-5 pb-0">
                      <strong>{{$t('contact.title')}}</strong>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      {{$t('contact.available')}}
                    </v-col>
                    <v-col cols="2" xl="1" class="pb-1 pt-1">
                      <v-icon>{{icons.mail}}</v-icon>
                    </v-col>
                    <v-col cols="10" xl="11" class="pb-1 pt-1">
                      <a href="mailto:info@ebikeworldfederation.com" target="_blank">info@ebikeworldfederation.com</a>
                    </v-col>
                    <v-col cols="2" xl="1" class="pb-0 pt-1">
                      <v-icon>{{icons.phone}}</v-icon>
                    </v-col>
                    <v-col cols="10" xl="11" class="pb-0 pt-1">
                      <a href="tel:0043484220607" target="_blank">+43 4842 20607</a>
                    </v-col>
                    <v-col cols="12" class="pb-2 pt-0">
                      {{$t('contact.paragraph2')}}
                    </v-col>
                    <v-form @submit="submit"
                            v-model="newsletter.valid"
                            ref="form" lazy-validation>
                      <v-row class="newsletter-form-container">
                        <v-col cols="2" xl="1" class="pb-0 pt-2">
                          <v-icon large>{{icons.newsletter}}</v-icon>
                        </v-col>
                        <v-text-field
                          label="Name"
                          class="name"
                          v-model="newsletter.name"
                          single-line
                        />
                        <v-col cols="8" xl="10" class="pb-0 pt-0">
                          <v-text-field
                            label="E-Mail"
                            v-model="newsletter.email"
                            :rules="newsletter.emailRules"
                            single-line validate-on-blur
                          />
                        </v-col>
                        <v-col cols="2" xl="1" class="pb-0 pt-0">
                          <v-btn icon type="submit"
                                 :loading="newsletter.loading"
                                 :disabled="newsletter.loading"
                                 style="margin-left: -10px;">
                            <v-icon large>{{icons.right}}</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title>
                  {{$t('contact.ebikewm')}}
                </v-card-title>
                <v-card-subtitle>
                  <v-row>
                    <v-col cols="4" sm="3" md="4" class="pb-0 pt-0 contact-logos-container">
                      <v-img src="https://ebikecup.com/images/EBWM_LOGO_DARK.png"/>
                    </v-col>
                    <v-col cols="8" sm="9" md="8" class="pb-0 pt-0">
                    </v-col>
                    <v-col cols="12" class="pt-3 pb-0">
                      <strong>{{$t('contact.title')}}</strong>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      {{$t('contact.available')}}
                    </v-col>
                    <v-col cols="2" xl="1" class="pb-1 pt-1">
                      <v-icon>{{icons.mail}}</v-icon>
                    </v-col>
                    <v-col cols="10" xl="11" class="pb-1 pt-1">
                      <a href="mailto:info@ebikeworldfederation.com" target="_blank">info@ebikeworldfederation.com</a>
                    </v-col>
                    <v-col cols="2" xl="1" class="pb-0 pt-1">
                      <v-icon>{{icons.phone}}</v-icon>
                    </v-col>
                    <v-col cols="10" xl="11" class="pb-0 pt-1">
                      <a href="tel:0043484220607" target="_blank">+43 4842 20607</a>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title>
                  {{$t('contact.ebikeWorldFederation')}}
                </v-card-title>
                <v-card-subtitle style="padding-bottom: 0;">
                  <v-row>
                    <v-col cols="4" sm="3" md="4" class="pb-0 pt-0 contact-logos-container">
                      <v-img src="https://ebikecup.com/images/EBIKE_WORLD_FEDERATION_Logo.svg"/>
                    </v-col>
                    <v-col cols="8" sm="9" md="8" class="pb-0 pt-0">
                    </v-col>
                    <v-col cols="12" class="pt-4 pb-0">
                      <strong>{{$t('contact.title')}}</strong>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      {{$t('contact.available')}}
                    </v-col>
                    <v-col cols="2" xl="1" class="pb-1 pt-1">
                      <v-icon>{{icons.mail}}</v-icon>
                    </v-col>
                    <v-col cols="10" xl="11" class="pb-1 pt-1">
                      <a href="mailto:info@ebikeworldfederation.com" target="_blank">info@ebikeworldfederation.com</a>
                    </v-col>
                    <v-col cols="2" xl="1" class="pb-0 pt-1">
                      <v-icon>{{icons.phone}}</v-icon>
                    </v-col>
                    <v-col cols="10" xl="11" class="pb-0 pt-1">
                      <a href="tel:0043484220607" target="_blank">+43 4842 20607</a>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
    <v-snackbar
      :timeout="snackbar.timeout"
      :bottom="snackbar.bottom"
      :color="snackbar.color"
      v-model="snackbar.value">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon
               @click="snackbar.value = false">
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  /* eslint-disable no-console */
  import {mdiEmail, mdiEmailNewsletter, mdiPhone, mdiChevronRight, mdiClose} from '@mdi/js'
  export default {
    name: 'contact',
    data() {
      return {
        snackbar: {
          value: false,
          text: '',
          color: 'success',
          timeout: 10000,
          bottom: true,
        },
        icons: {
          mail: mdiEmail,
          newsletter: mdiEmailNewsletter,
          phone: mdiPhone,
          right: mdiChevronRight,
          close: mdiClose,
        },
        newsletter: {
          loading: false,
          valid: undefined,
          email: null,
          name: null,
          emailRules: [
            v => !!v || this.$t('required'),
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailNotValid'),
          ],
        },
      };
    },
    methods: {
      submit(e) {
        e.preventDefault();
        if (this.$refs.form.validate()) {
          this.newsletter.loading = true;
          const newsletter = {
            email: this.newsletter.email,
            name: this.newsletter.name,
          };
          this.$http.post('newsletter',
          newsletter).then(function success(data) {
              if (data.body.registration === 'ALREADY REGISTERED') {
                this.snackbar.text = this.$t('newsletter.exists');
              } else {
                this.snackbar.text = this.$t('newsletter.success');
              }
              this.snackbar.color = 'success';
              this.newsletter.email = '';
              this.snackbar.value = true;
              this.newsletter.loading = false;
              this.$refs.form.reset();
            },
            function error() {
              this.snackbar.text = this.$t('newsletter.error');
              this.snackbar.color = 'error';
              this.snackbar.value = true;
              this.newsletter.loading = false;
            },
          );
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";
  .contact-container {
    .v-card {
      background-color: $basic-gray;
      text-align: left;
      min-height: 350px;
      height: 100%;
      @include MQ(XXL) {
        min-height: 363px;
      }
      @include MQ(L) {
        min-height: 380px;
      }
      @include MQ(M) {
        min-height: 400px;
      }
      @include MQ(S) {
        min-height: 0;
      }
      @include MQ(XS) {
        min-height: 0;
      }
      .v-card__title {
        font-weight: 600;
        margin-bottom: 15px;
      }
      .v-card__subtitle {
        color: $primary-text;
        .v-icon {
          color: $primary;
          margin-top: -5px;
        }
        a {
          text-decoration: none;
          color: $primary;
          font-weight: 600;
        }
      }
    }
    form {
      width: 100%;
      .newsletter-form-container {
        margin: 0;
        .name {
          display: none;
        }
        .v-text-field {
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
    .contact-logos-container {
      height: 80px;
      .v-image {
        height: 100%;
        .v-image__image--cover {
          background-size: contain;
        }
      }
    }
  }
</style>
